import { CASE_CATEGORY } from '../Game';

const cases = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ]

  export default cases as CASE_CATEGORY[];
